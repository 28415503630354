import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label, FormGroup, Col } from 'reactstrap';
import DataTable from '../../common/DataTable';
import appSubmissionStatus from '../../../enums/appSubmissionStatus';
import appType from '../../../enums/appType';
import { ReactComponent as InfoIcon } from '../../../content/icons/Icon-Info.svg';
import { Fragment } from 'react';
import AppSubmissionStatusTag from '../AppSubmissionStatusTag';
import LoadingSpinner from '../../common/LoadingSpinner';
import AppService from '../../../services/admin/AppService';

function AppSubmissionHistoryLog(props) {
    const [appSubmissionLogs, setAppSubmissionLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        AppService.getAppSubmissionLogs(props.application.id)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setAppSubmissionLogs(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.application]);

    const appSubmissionRemarks = (appSubmission) => {
        let header = '';
        let remarks = '';
        switch (appSubmission.submissionStatus) {
            case appSubmissionStatus.submitted:
                let isResubmitted = appSubmissionLogs.some(
                    (x) =>
                        x.submissionStatus === appSubmissionStatus.submitted &&
                        new Date(x.updatedDate) < new Date(appSubmission.updatedDate)
                );
                header = isResubmitted ? 'App resubmitted:' : 'App submitted:';
                remarks = 'Pending admin approval';
                break;
            case appSubmissionStatus.approved:
                header = props.application.appType === appType.private ? 'Approve and live' : 'Approved';
                break;
            case appSubmissionStatus.live:
                header = 'Approve and live';
                break;
            case appSubmissionStatus.inDevelopment:
            default:
                header = 'In Development';
                break;
        }

        return (
            <div>
                <h6>{header}</h6>
                {remarks && <span>{remarks}</span>}
            </div>
        );
    };

    const columns = [
        {
            name: 'DATE/TIME',
            className: 'date',
            selector: (x) => x.updatedDate.replace('T', ' ').replace(/\.\d+/, ''),
        },
        {
            name: 'STATUS',
            className: 'status',
            displayFormat: (x) => {
                return <AppSubmissionStatusTag submissionStatus={x.submissionStatus} />;
            },
        },
        {
            name: 'Remarks',
            className: 'remarks',
            displayFormat: (x) => appSubmissionRemarks(x),
        },
        {
            name: 'UPDATED BY',
            className: 'update-by',
            selector: (x) => x.updatedByUser,
        },
        {
            name: 'Comments',
            className: 'comments',
            selector: (x) => x.comments,
        },
    ];

    return (
        <Fragment>
            {isLoading ? (
                <LoadingSpinner loadingText="Loading..." />
            ) : (
                <FormGroup row>
                    {appSubmissionLogs.length > 0 && (
                        <Fragment>
                            <Label sm={12} className="pt-0 pb-0">
                                <h5>Logs</h5>
                            </Label>
                            <Col className="app-submission-logs" sm={12}>
                                <DataTable columns={columns} data={appSubmissionLogs} />
                            </Col>
                        </Fragment>
                    )}
                    {appSubmissionLogs?.length === 0 && (
                        <div className="submission-page-info-section mb-3">
                            <h6>
                                <InfoIcon />
                                No Logs
                            </h6>
                        </div>
                    )}
                </FormGroup>
            )}
        </Fragment>
    );
}

AppSubmissionHistoryLog.propTypes = {
    application: PropTypes.object.isRequired,
    submissionRejectionReasons: PropTypes.arrayOf(PropTypes.object),
};

export default AppSubmissionHistoryLog;
