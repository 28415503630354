/* eslint-disable */
import AppStartingGuideSection from '../domainObjects/AppStartingGuideSection';
import { api } from '../helpers/ApiHelper';
import { getEnvironment } from '../helpers/EnvironmentHelper';
import App from '../model/appManagement/App';
import AppAuthenticationSettings from '../model/appManagement/AppAuthenticationSettings';
import AppInstallation from '../model/appManagement/AppInstallation';
import AppProgress from '../model/appManagement/AppProgress';
import AppStoreListing from '../model/appManagement/AppStoreListing';
import AppStoreListingImage from '../model/appManagement/AppStoreListingImage';
import AppSubmission from '../model/appManagement/AppSubmission';
import RotateClientSecret from '../model/appManagement/RotateClientSecret';
import ErrorMessage from '../model/services/ErrorMessage';
import PagedResult from '../model/utility/PagedResult';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const getApplicationAuthenticationSettings = async (appId: string, isTestEnvironment: boolean) => {
    return await api
        .get<AppAuthenticationSettings, string>(`Apps/${appId}/AuthenticationSettings`, {
            environment: getEnvironment(isTestEnvironment),
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const saveApplicationAuthenticationSettings = async (authenticationSettings: AppAuthenticationSettings) => {
    const response = await api
        .post<AppAuthenticationSettings, string>(
            `Apps/${authenticationSettings.appId}/AuthenticationSettings`,
            authenticationSettings
        )
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
    return response;
};

const rotateClientSecret = async (authenticationSettings: AppAuthenticationSettings) => {
    return await api
        .post<RotateClientSecret, string>(`Apps/${authenticationSettings.appId}/AuthenticationSettings/rotate-secret`, {
            ...authenticationSettings,
            clientSecret: '',
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const getApplications = async () => {
    return await api
        .get<App[], string>(`Apps`)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const getApplicationById = async (appId: string) => {
    return await api.get<App, string>(`Apps/${appId}`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const createApplication = async (title: string, organisationId: number, appType: string, apiType: string) => {
    return await api
        .post<App, string>(
            `Apps`,
            JSON.stringify({
                title: title,
                organisationId: organisationId,
                appType: appType,
                apiType: apiType,
            })
        )
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const getApplicationProgress = async (appId: number) => {
    return await api.get<AppProgress, string>(`Apps/${appId}/progress`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const getProgressForApplications = async (organisationId: number) => {
    return await api
        .get<AppProgress[], string>(`Apps/progress`, {
            organisationId,
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const updateApplicationBasicInfo = async (appId: number, app: App) => {
    return await api.put<App, string>(`Apps/${appId}`, app).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const getApplicationLogoUrl = async (organisationId: number, appId: string) => {
    return await api
        .get<string, string>(`Apps/${appId}/logo`, {
            organisationId,
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const getApplicationSubmission = async (appId: number) => {
    return await api.get<AppSubmission, string>(`Apps/${appId}/Submission`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const getApplicationInstallations = async (appId: number, status: string, pageNumber: number, pageSize: number) => {
    return await api
        .get<PagedResult<AppInstallation>, string>(`Apps/${appId}/installations`, {
            status,
            pageNumber,
            pageSize,
        })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const updateApplicationSubmission = async (appId: number, appSubmission: AppSubmission) => {
    return await api.post<AppSubmission, string>(`Apps/${appId}/Submission`, appSubmission).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const getStartingGuide = async (appId: number) => {
    return await api.get<AppStartingGuideSection, string>(`Apps/${appId}/StartingGuide`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const saveStartingGuide = async (appId: number, startingGuide: FormData) => {
    return await api
        .post<AppStartingGuideSection, string>(`Apps/${appId}/StartingGuide`, startingGuide)
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    return errorMessage;
                }
            } else {
                return undefinedResponse();
            }
        });
};

const createOrUpdateAppStoreListingFromApp = async (app: App) => {
    return await api.post<AppStoreListing, string>(`Apps/${app.id}/storeListingFromApp`, app).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const createOrUpdateAppStoreListing = async (appId: number, appStoreListing: FormData) => {
    return await api.post<AppStoreListing, string>(`Apps/${appId}/storeListing`, appStoreListing).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const getAppStoreListing = async (appId: number) => {
    return await api.get<AppStoreListing, string>(`Apps/${appId}/storeListing`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            }
        } else {
            return undefinedResponse();
        }
    });
};

const updateSubmissionStatus = async (appId: number, submissionStatus: string) => {
    return await api.put<string, string>(`Apps/${appId}/submission/${submissionStatus}`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const getAllAppStoreListingImageUrls = async (appId: number, orgId: number) => {
    return await api
        .get<AppStoreListingImage[], string>(`Apps/${appId}/storeListing/images`, { orgId: orgId })
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const uploadAppStoreListingImages = async (appId: number, images: FormData) => {
    return await api.post<undefined, string>(`Apps/${appId}/storeListing/images`, images).then((response) => {
        if (response.data === undefined && response.ok) {
            return true;
        } else if (response.data !== undefined && !response.ok) {
            let errorMessage: ErrorMessage = { errorMessage: response.data };
            return errorMessage;
        } else {
            return undefinedResponse();
        }
    });
};

const deleteAppStoreListingImage = async (appId: number, imageNumber: number, orgId: number) => {
    return await api
        .delete<undefined, string>(`Apps/${appId}/storeListing/images/${imageNumber}`, { orgId: orgId })
        .then((response) => {
            if (response.data === undefined && response.ok) {
                return true;
            } else if (response.data !== undefined && !response.ok) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                return errorMessage;
            } else {
                return undefinedResponse();
            }
        });
};

const setAppStoreListingIsLive = async (appId: string, isLive: boolean) => {
    return await api
        .put<boolean, string>(
            `Apps/${appId}/storeListing/isLive`,
            JSON.stringify({
                isLive: isLive,
            })
        )
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

export default {
    getApplications,
    getApplicationById,
    getApplicationSubmission,
    createApplication,
    getApplicationProgress,
    getProgressForApplications,
    updateApplicationBasicInfo,
    getApplicationLogoUrl,
    updateApplicationSubmission,
    getApplicationInstallations,
    getStartingGuide,
    saveStartingGuide,
    getApplicationAuthenticationSettings,
    saveApplicationAuthenticationSettings,
    createOrUpdateAppStoreListing,
    getAppStoreListing,
    updateSubmissionStatus,
    getAllAppStoreListingImageUrls,
    uploadAppStoreListingImages,
    deleteAppStoreListingImage,
    setAppStoreListingIsLive,
    rotateClientSecret,
    createOrUpdateAppStoreListingFromApp,
};
