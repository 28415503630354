/* eslint-disable */
import { api } from '../helpers/ApiHelper';
import TestProvider from '../model/TestDiaries/TestProvider';
import ErrorMessage from '../model/services/ErrorMessage';

const undefinedResponse = () => {
    let errorMessage: ErrorMessage = { errorMessage: 'Undefined response' };
    return errorMessage;
};

const createTestDiary = async (organisationId: number) => {
    return await api.post<TestProvider, string>(`TestDiaries/${organisationId}`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const getTestDiaries = async () => {
    return await api.get<TestProvider[], string>(`TestDiaries`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

const installUninstallApp = async (appId: number, testDiaryId: number, isInstall: boolean) => {
    return await api
        .post<undefined, string>(`TestDiaries/${appId}/${testDiaryId}/${isInstall}/InstallUninstall`)
        .then((response) => {
            if (response.data === undefined && response.ok) {
                return true;
            } else if (!response.ok && response.data !== undefined) {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            } else {
                throw undefinedResponse();
            }
        });
};

const linkTestDiary = async (organisationId: number, venueName: string, venueId: number, micrositeName: string) => {
    return await api
        .post<TestProvider, string>(
            `TestDiaries/Link`,
            JSON.stringify({
                organisationId: organisationId,
                venueName: venueName,
                venueId: venueId,
                micrositeName: micrositeName,
            })
        )
        .then((response) => {
            if (response.data !== undefined) {
                if (response.ok) {
                    return response.data;
                } else {
                    let errorMessage: ErrorMessage = { errorMessage: response.data };
                    throw errorMessage;
                }
            } else {
                throw undefinedResponse();
            }
        });
};

const isValidTestVenue = async (venueId: string) => {
    return await api.post<boolean, string>(`TestDiaries/${venueId}/isValid`).then((response) => {
        if (response.data !== undefined) {
            if (response.ok) {
                return response.data;
            } else {
                let errorMessage: ErrorMessage = { errorMessage: response.data };
                throw errorMessage;
            }
        } else {
            throw undefinedResponse();
        }
    });
};

export default {
    createTestDiary,
    getTestDiaries,
    isValidTestVenue,
    linkTestDiary,
    installUninstallApp,
};
