import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as WarningIcon } from '../../../../content/icons/Icon-Warning.svg';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ClientSecretWarningModal = (props: ClientSecretWarningModalProps) => {
    const closeModal = () => {
        props.closeModal();
    };

    const openClientSecretModal = () => {
        closeModal();
        props.openClientSecretModal();
    };

    return (
        <Modal
            centered
            isOpen={props.isOpen}
            size="md"
            className="client-secret-warning-modal"
            toggle={() => {
                closeModal();
            }}
        >
            <ModalHeader toggle={() => closeModal()}>
                <div className="d-flex align-items-center title">
                    <WarningIcon className="mr-2" />
                    <FormattedMessage id="AppManagement.AppDetails.Warning" />
                </div>
            </ModalHeader>
            <ModalBody>
                <FormattedMessage id="AppManagement.AppDetails.NewKeyGenerationWarning" />
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => openClientSecretModal()} color="dark" className="text-uppercase">
                    <FormattedMessage id="AppManagement.AppDetails.Proceed" />
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export interface ClientSecretWarningModalProps {
    isOpen: boolean;
    openClientSecretModal(): void;
    closeModal(): void;
}

export default ClientSecretWarningModal;
