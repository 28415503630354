import * as React from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import App from '../../../model/appManagement/App';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../common/LoadingSpinner';
import AppSubmissionStatusTag from '../AppSubmissionStatusTag';

export interface AppsTableProps {
    apps?: App[];
    isLoading?: boolean;
}

const AppsTable = (props: AppsTableProps) => {
    const navigate = useNavigate();

    return (
        <div>
            {props.isLoading ? (
                <LoadingSpinner />
            ) : (
                <Fragment>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ backgroundColor: '#e5e9f0' }}>
                                <TableRow>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.AppNameCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.IdCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.OrganisationsCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.ApiCol" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="Admin.AppList.StatusCol" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.apps &&
                                    props.apps.map((app, index) => (
                                        <TableRow key={index} onClick={() => navigate(`${app.appId}`)}>
                                            <TableCell className="btn btn-link">{app.title}</TableCell>
                                            <TableCell>{app.appId}</TableCell>
                                            <TableCell>{app.organisationName}</TableCell>
                                            <TableCell>{app.apiType}</TableCell>
                                            <TableCell>
                                                <AppSubmissionStatusTag submissionStatus={app.submissionStatus} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fragment>
            )}
        </div>
    );
};
export default AppsTable;
