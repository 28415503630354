import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import useValidation from '../../hooks/useValidation';
import {
    Alert,
    Button,
    Label,
    Form,
    FormGroup,
    Col,
    CustomInput,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from 'reactstrap';
import LoadingSpinner from '../common/LoadingSpinner';
import Tag from '../common/Tag';
import ValidationInput from '../common/ValidationInput';
import tagColour from '../../enums/tagColour';
import appSubmissionStatus from '../../enums/appSubmissionStatus';
import { ReactComponent as DeleteIcon } from '../../content/icons/Icon-Delete.svg';
import { ReactComponent as SuccessIcon } from '../../content/icons/Icon-Success.svg';
import { ReactComponent as AlertIcon } from '../../content/icons/Icon-Alert.svg';
import { ReactComponent as InfoIcon } from '../../content/icons/Icon-Info.svg';
import AppService from '../../services/AppService';
import appNavigationTabs from '../../enums/appNavigationTabs';
import ApplicationProgressHelper from '../../helpers/ApplicationProgressHelper';
import MomentHelper from '../../helpers/MomentHelper';
import { Fragment } from 'react';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import AppSubmissionHistoryLog from './admin/AppSubmissionHistoryLog';
import AppSubmissionApprovalModal from './admin/AppSubmissionApprovalModal';
import { FormattedMessage, useIntl } from 'react-intl';
import AdminAppService from '../../services/admin/AppService';
import AppSubmission from '../../model/appManagement/AppSubmission';
import AppSubmissionLog from '../../model/appManagement/admin/AppSubmissionLog';
import App from '../../model/appManagement/App';
import AppProgress from '../../model/appManagement/AppProgress';

function AppSubmissionPage(props: AppSubmissionPageProps): JSX.Element {
    const intl = useIntl();
    const { authInfo } = useAuthentication();
    const { register, errors, submit } = useValidation();
    const [application, setApplication] = useState(props.application);
    const [appSubmission, setAppSubmission] = useState<AppSubmission>();
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenIncompleteAppModal, setIsOpenIncompleteAppModal] = useState<boolean>(false);
    const [isFormDisabled] = useState(
        props.application?.submissionStatus === appSubmissionStatus.submitted ||
            props.application?.submissionStatus === appSubmissionStatus.approved
    );
    const [isAdminEditing, setIsAdminEditing] = useState<boolean>(false);
    const [isOpenAdminSubmissionApprovalModal, setIsOpenAdminSubmissionApprovalModal] = useState<boolean>(false);
    const [appSubmissionLogs, setAppSubmissionLogs] = useState<AppSubmissionLog[]>([]);

    const submissionRejectionReasons = [
        { label: intl.formatMessage({ id: 'Admin.MissingAppInformation' }), value: 1 },
        { label: intl.formatMessage({ id: 'Admin.RedirectUrlMissingFailing' }), value: 2 },
        { label: intl.formatMessage({ id: 'Admin.CertificationTestingFailed' }), value: 3 },
        { label: intl.formatMessage({ id: 'Admin.InsufficientVideoExplanation' }), value: 4 },
        { label: intl.formatMessage({ id: 'Admin.MissingWebhookSetup' }), value: 5 },
        { label: intl.formatMessage({ id: 'Admin.StagingEnvironmentRequired' }), value: 6 },
    ];

    useEffect((): void => {
        if (authInfo.isAdmin) {
            AdminAppService.getAppSubmissionLogs(props.application.id)
                .then((response) => {
                    if (!('errorMessage' in response)) {
                        setAppSubmissionLogs(response);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [props.application, authInfo.isAdmin]);

    useEffect((): void => {
        AppService.getApplicationSubmission(props.application.id)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setAppSubmission(response);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.application.id]);

    const toggleAdminEditing = (): void => {
        setIsAdminEditing(!isAdminEditing);
    };

    const setSubmissionFieldValue = useCallback(
        (fieldName: string, value: any) => {
            const app: any = { ...appSubmission };
            app.keys()[fieldName] = value;
            setAppSubmission(app);
        },
        [appSubmission]
    );

    const setCategoryVideo = useCallback(
        (categoryIndex: number, videoIndex: number, fieldName: string, value: any): void => {
            const app: any = { ...appSubmission };
            app.endToEndVideos[categoryIndex].videos[videoIndex][fieldName] = value;
            setAppSubmission(app);
        },
        [appSubmission]
    );

    const addVideoItem = (categoryIndex: number): void => {
        const app: any = { ...appSubmission };
        app.endToEndVideos[categoryIndex].videos.push({ key: Date.now() });
        setAppSubmission(app);
    };

    const removeVideoItem = (categoryIndex: number, videoIndex: number): void => {
        const videos: any = { ...appSubmission };
        videos.endToEndVideos[categoryIndex].videos = videos.endToEndVideos[categoryIndex].videos.filter(
            (_: any, i: number) => i !== videoIndex
        );
        setAppSubmission(videos);
    };

    const onIncompleteAppModalClose = (): void => {
        setIsOpenIncompleteAppModal(false);
    };

    const toggleAdminSubmissionApprovalModal = (): void => {
        setIsOpenAdminSubmissionApprovalModal(!isOpenAdminSubmissionApprovalModal);
    };

    const redirectToGetStartedPage = (): void => {
        props.onTabChanged(appNavigationTabs.getStarted);
    };

    const updateApplicationSubmission = () => {
        return AppService.updateApplicationSubmission(props.application.id, appSubmission!).then((response) => {
            if (!('errorMessage' in response)) {
                props.application.submissionStatus = response.submissionStatus;
                props.application.submittedDate = response.submittedDate;
            }
            return response;
        });
    };

    const withdrawApplicationSubmission = (): void => {
        appSubmission!.submissionStatus = appSubmissionStatus.inDevelopment;
        updateApplicationSubmission().then((response) => {
            if (!('errorMessage' in response)) {
                props.onSuccess(intl.formatMessage({ id: 'AppManagement.AppSubmission.WithdrawnSuccess' }));
            } else {
                props.onError(response.errorMessage);
            }
        });
    };

    const saveApplicationSubmission = (): void => {
        if (!authInfo.isAdmin) {
            appSubmission!.submissionStatus = appSubmissionStatus.inDevelopment;
        }

        updateApplicationSubmission().then((response) => {
            if (!('errorMessage' in response)) {
                props.onSuccess(intl.formatMessage({ id: 'AppManagement.AppSubmission.SubmissionSaved' }));
                if (authInfo.isAdmin) {
                    toggleAdminEditing();
                }
            } else {
                props.onError(response.errorMessage);
            }
        });
    };

    const submitApplication = (): void => {
        if (ApplicationProgressHelper.getProgress(props.appProgress) === 80) {
            appSubmission!.submissionStatus = appSubmissionStatus.submitted;
            updateApplicationSubmission().then((response) => {
                if (!('errorMessage' in response)) {
                    props.onSuccess(intl.formatMessage({ id: 'AppManagement.AppSubmission.SubmittedSuccess' }));
                } else {
                    props.onError(response.errorMessage);
                }
            });
        } else {
            setIsOpenIncompleteAppModal(true);
        }
    };

    const renderVideoListItem = (data: any, categoryIndex: any) => {
        if (data.videos?.length <= 0) {
            data.isEmpty = true;
            data.videos.push({ key: Date.now() });
        }
        return (
            <Col sm={12} md={12} key={`category-${categoryIndex}`} className="video-category-list">
                <Label check className="mb-2">
                    <CustomInput
                        name={`category-${categoryIndex}`}
                        id={`category-${categoryIndex}`}
                        type="checkbox"
                        label={data.category}
                        onChange={() => {}}
                        checked={data.videos.length > 0}
                        disabled={isFormDisabled}
                    />
                </Label>
                {data.videos.map((video: any, videoIndex: number) => {
                    return (
                        <Col
                            key={`category-${videoIndex}-video-${video.key}`}
                            className="video-list-item"
                            sm={12}
                            md={12}
                        >
                            <FormGroup className="col-sm-5 video-title">
                                <div className="d-flex">
                                    <Label for="title">
                                        <FormattedMessage id="AppManagement.AppSubmission.VideoTitle" />
                                    </Label>
                                </div>
                                <ValidationInput
                                    testId={`title-${categoryIndex}-${videoIndex}`}
                                    type="text"
                                    name={`title-${categoryIndex}-${videoIndex}`}
                                    value={video.title}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setCategoryVideo(categoryIndex, videoIndex, 'title', e.currentTarget.value)
                                    }
                                    innerRef={register({
                                        required: intl.formatMessage({
                                            id: 'AppManagement.AppSubmission.VideoTitleRequired',
                                        }),
                                    })}
                                    maxLength={25}
                                    errors={errors}
                                    displayCharacterCount={true}
                                    disabled={isFormDisabled}
                                />
                            </FormGroup>
                            <FormGroup className="col video-url">
                                <Label for="url">
                                    <FormattedMessage id="AppManagement.AppSubmission.VideoUrl" />
                                </Label>
                                <ValidationInput
                                    testId={`url-${categoryIndex}-${videoIndex}`}
                                    type="url"
                                    value={video.url}
                                    name={`url-${categoryIndex}-${videoIndex}`}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setCategoryVideo(categoryIndex, videoIndex, 'url', e.currentTarget.value)
                                    }
                                    innerRef={register({
                                        required: intl.formatMessage({
                                            id: 'AppManagement.AppSubmission.VideoUrlRequired',
                                        }),
                                        pattern: {
                                            value: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi, // eslint-disable-line no-control-regex
                                            message: intl.formatMessage({
                                                id: 'AppManagement.AppSubmission.VideoUrlValidation',
                                            }),
                                        },
                                    })}
                                    errors={errors}
                                    disabled={isFormDisabled}
                                />
                            </FormGroup>
                            <FormGroup className="action-buttons">
                                <Button
                                    disabled={data.videos.length <= 1 || isFormDisabled}
                                    className="btn-delete"
                                    onClick={() => removeVideoItem(categoryIndex, videoIndex)}
                                >
                                    <DeleteIcon />
                                </Button>
                            </FormGroup>
                        </Col>
                    );
                })}
                <Button className="btn-add" disabled={isFormDisabled} onClick={() => addVideoItem(categoryIndex)}>
                    +&nbsp;
                    <FormattedMessage id="AppManagement.AppSubmission.AddMore" />
                </Button>
            </Col>
        );
    };

    const renderAppSubmissionInfo = (submissionStatus: string) => {
        switch (submissionStatus) {
            case appSubmissionStatus.approved:
                return (
                    <div className="approved">
                        <h6>
                            <SuccessIcon />
                            <FormattedMessage id="AppManagement.AppSubmission.ApprovedAndLive" />
                        </h6>
                    </div>
                );
            case appSubmissionStatus.submitted:
                return (
                    <div className="submitted">
                        <h6>
                            <InfoIcon />
                            {intl.formatMessage(
                                { id: 'AppManagement.AppSubmission.SubmittedOn' },
                                { date: MomentHelper.newInstance(props.application.submittedDate).format('LL') }
                            )}
                        </h6>
                        <p>
                            <FormattedMessage id="AppManagement.AppSubmission.UnderReview" />
                        </p>
                    </div>
                );
            case appSubmissionStatus.live:
                return (
                    <div className="rejected">
                        <Alert color="warning">
                            <h6>
                                <AlertIcon />
                                <FormattedMessage id="AppManagement.AppSubmission.SubmissionFailed" />
                            </h6>
                            <p>
                                <FormattedMessage id="AppManagement.AppSubmission.FixTitle" />
                            </p>
                            <p className="mb-0">
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                mollit anim id est laborum"
                            </p>
                        </Alert>
                    </div>
                );
            case appSubmissionStatus.inDevelopment:
            default:
                return (
                    <Fragment>
                        <FormattedMessage id="AppManagement.AppSubmission.UrlMessage" />
                    </Fragment>
                );
        }
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const renderAdminButtonGroup = (): JSX.Element => {
        return (
            <Fragment>
                {props.application?.submissionStatus !== appSubmissionStatus.inDevelopment && !isAdminEditing && (
                    <Button color="primary" onClick={toggleAdminSubmissionApprovalModal} className="btn mr-2">
                        <FormattedMessage id="AppManagement.AppSubmission.UpdateSubmission" />
                    </Button>
                )}
                {isAdminEditing && (
                    <Button color="primary" onClick={saveApplicationSubmission} className="btn mr-2">
                        <FormattedMessage id="AppManagement.AppSubmission.SaveContinue" />
                    </Button>
                )}
                <Button onClick={toggleAdminEditing} className="btn">
                    {isAdminEditing ? (
                        <FormattedMessage id="AppManagement.AppSubmission.Cancel" />
                    ) : (
                        <FormattedMessage id="AppManagement.AppSubmission.EditForm" />
                    )}
                </Button>
            </Fragment>
        );
    };

    const renderDeveloperButtonGroup = (): JSX.Element | undefined => {
        switch (props.application?.submissionStatus) {
            case appSubmissionStatus.inDevelopment:
                return (
                    <Fragment>
                        <Button onClick={saveApplicationSubmission} className="btn">
                            Save
                        </Button>
                        <Button type="submit" className="ml-2 btn" color="primary">
                            <FormattedMessage id="AppManagement.AppSubmission.Submit" />
                        </Button>
                    </Fragment>
                );
            case appSubmissionStatus.submitted:
                return (
                    <Button onClick={withdrawApplicationSubmission} className="btn">
                        <FormattedMessage id="AppManagement.AppSubmission.WithdrawSubmission" />
                    </Button>
                );
            default:
                return;
        }
    };

    const appSubmissionApprovalOnConfirm = (response: any): void => {
        if (response.ok) {
            props.onSuccess(intl.formatMessage({ id: 'AppManagement.AppSubmission.WithdrawnSuccess' }));
        } else {
            props.onError(intl.formatMessage({ id: 'AppManagement.AppSubmission.SubmissionProcessFail' }));
        }
        // Trigger state change to application to update the App Submission Log List
        setApplication({ ...application });
    };

    return (
        <div className="submission-page">
            <h1 className="mb-3">
                {authInfo.isAdmin ? (
                    <FormattedMessage id="AppManagement.AppSubmission.ManageSubmission" />
                ) : (
                    <FormattedMessage id="AppManagement.AppSubmission.Submit" />
                )}
            </h1>
            {authInfo.isAdmin ? (
                <AppSubmissionHistoryLog
                    application={application}
                    submissionRejectionReasons={submissionRejectionReasons}
                />
            ) : (
                <div className="submission-page-info-section mb-3">
                    {renderAppSubmissionInfo(props.application?.submissionStatus)}
                </div>
            )}
            <hr />
            <br />
            <Form onSubmit={submit(submitApplication)}>
                <FormGroup row>
                    <Label sm={12} className="pt-0 pb-0">
                        <h6>
                            <FormattedMessage id="AppManagement.AppSubmission.DescriptionTitle" />
                        </h6>
                    </Label>
                    <Label sm={3} className="pt-0 pb-0">
                        <span>
                            <FormattedMessage id="AppManagement.AppSubmission.DescriptionPrompt" />
                        </span>
                    </Label>
                    <Col sm={7}>
                        <ValidationInput
                            testId="appSubmissionAppDescription"
                            type="textarea"
                            rows="4"
                            name="appDescription"
                            placeholder={intl.formatMessage({ id: 'AppManagement.AppSubmission.DescriptionTitle' })}
                            value={appSubmission!.appDescription}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setSubmissionFieldValue('appDescription', e.currentTarget.value)
                            }
                            innerRef={register({
                                required: intl.formatMessage({ id: 'AppManagement.AppSubmission.DescriptionRequired' }),
                            })}
                            errors={errors}
                            disabled={isFormDisabled && !isAdminEditing}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={3}>
                        <h6>
                            <FormattedMessage id="AppManagement.AppSubmission.SelectedApi" />
                        </h6>
                    </Label>
                    <Col sm={7}>
                        <div className="col-md-3 pl-0">
                            <Tag value={props.application.apiType} color={tagColour.Navy} />
                        </div>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label sm={12} className="pt-0 pb-0">
                        <h6>
                            <FormattedMessage id="AppManagement.AppSubmission.EndToEndVideos" />
                        </h6>
                    </Label>
                    <Label sm={3} className="pt-0 pb-0">
                        <span>
                            <FormattedMessage id="AppManagement.AppSubmission.EndToEndDescription" />
                        </span>
                    </Label>
                    <Col sm={7}>{appSubmission?.endToEndVideos?.map(renderVideoListItem)}</Col>
                </FormGroup>

                <hr />
                <FormGroup row>
                    <div className="col-md-5 modal-button-group">
                        {authInfo.isAdmin ? renderAdminButtonGroup() : renderDeveloperButtonGroup()}
                    </div>
                </FormGroup>
            </Form>
            {!authInfo.isAdmin && (
                <Modal centered isOpen={isOpenIncompleteAppModal} toggle={onIncompleteAppModalClose}>
                    <ModalHeader className="border-bottom-0" toggle={onIncompleteAppModalClose}>
                        <FormattedMessage id="AppManagement.AppSubmission.SubmitUnable" />
                    </ModalHeader>
                    <ModalBody>
                        <span>
                            <FormattedMessage id="AppManagement.AppSubmission.SubmitAppCompletePrompt" />
                        </span>
                        <br />
                        <span>
                            <FormattedMessage id="AppManagement.AppSubmission.CheckProgressDescription" />
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={redirectToGetStartedPage}>
                            <FormattedMessage id="AppManagement.AppSubmission.CheckProgress" />
                        </Button>
                    </ModalFooter>
                </Modal>
            )}

            {authInfo.isAdmin && (
                <AppSubmissionApprovalModal
                    isOpen={isOpenAdminSubmissionApprovalModal}
                    application={props.application}
                    toggle={toggleAdminSubmissionApprovalModal}
                    onConfirm={appSubmissionApprovalOnConfirm}
                    submissionRejectionReasons={submissionRejectionReasons}
                    latestAppSubmissionLog={
                        appSubmissionLogs?.sort((a, b) => {
                            return a.updatedDate > b.updatedDate ? 1 : -1;
                        })[0]
                    }
                />
            )}
        </div>
    );
}

interface AppSubmissionPageProps {
    application: App;
    appProgress: AppProgress;
    onTabChanged(tabKey: string): void;
    onSuccess(message: string): void;
    onError(message: string): void;
}

export default AppSubmissionPage;
