import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
//import _ from 'lodash'; // eslint-disable-line
import ApiLogService from '../../services/ApiLogService';
import TextWithIcon from '../common/TextWithIcon';
import { ReactComponent as BackIcon } from '../../content/icons/Icon-Arrow-Left.svg';
import LoadingSpinner from '../common/LoadingSpinner';
import MomentHelper from '../../helpers/MomentHelper';

interface LogDetailsProps {
    application: any;
    requestId: any;
    environment: string;
    setIsDisplayingLogEntry: Function;
}
//TODO: update all the any types to defined types
function LogDetails(props: LogDetailsProps) {
    const [apiRequestLogEntryDetails, setApiRequestLogEntryDetails] = useState<any>(null);
    // eslint-disable-next-line
    const [errorCollapsed, setErrorCollapsed] = useState({}); // map of error.id -> collapsed
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ApiLogService.getApiRequestLogEntryDetails({
            id: props.requestId,
            environment: props.environment,
            organisationId: props.application.organisationId,
            appId: props.application.id,
        })
            .then((result: any) => {
                if (!('errorMessage' in result)) {
                    setApiRequestLogEntryDetails(result);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [props.application.id, props.application.organisationId, props.environment, props.requestId]);

    const handleBackToLogList = () => {
        props.setIsDisplayingLogEntry(false);
    };

    const isResponseStatusOk = (responseStatus: number) => responseStatus < 400;
    const getStatusDotClassName = () => {
        return isResponseStatusOk(apiRequestLogEntryDetails.responseStatus) ? 'status-dot' : 'status-dot--fail';
    };

    const renderRequest = (apiRequestLogEntryDetails: any) => {
        const hasRequestBody = apiRequestLogEntryDetails.requestBody && apiRequestLogEntryDetails.requestBody.length;
        return (
            <div className="code">
                <div className="code__header">
                    <span className="code__header__title">Request</span>
                </div>
                <pre>
                    <code>{apiRequestLogEntryDetails.requestHeaders}</code>
                </pre>
                {hasRequestBody ? (
                    <>
                        <div className="code-separator"></div>
                        <pre>
                            <code>{apiRequestLogEntryDetails.requestBody}</code>
                        </pre>
                    </>
                ) : null}
            </div>
        );
    };

    function getFormattedDate(timeStamp: string) {
        return MomentHelper.newInstance(timeStamp).format('DD/MM/YYYY hh:mm:ss A Z').replace('T', ' ');
    }

    const renderResponse = (apiRequestLogEntryDetails: any) => {
        let responseStatusHeaderClassName = isResponseStatusOk(apiRequestLogEntryDetails.responseStatus)
            ? 'code__header'
            : 'code__header fail';
        return (
            <div className="code">
                <div className={responseStatusHeaderClassName}>
                    <span className="code__header__title">Response</span>
                </div>
                <pre>
                    <code>{apiRequestLogEntryDetails.responseHeaders}</code>
                </pre>
                <div className="code-separator"></div>
                <pre>
                    <code>{apiRequestLogEntryDetails.responseBody}</code>
                </pre>
            </div>
        );
    };

    if (!apiRequestLogEntryDetails) {
        return null; // TODO: add loading icon
    }

    const renderSummary = (apiRequestLogEntryDetails: any) => {
        const requestSummaryObject: any = {};
        const requestSummaryDataArrays = apiRequestLogEntryDetails.requestHeaders
            .split(',')
            .map((item: string) => item.split(':'));
        requestSummaryDataArrays.forEach((array: any) => {
            const [first, ...rest] = array;
            const remainder = rest.join(':');
            requestSummaryObject[first.trim().replace('"', '')] = remainder;
        });

        return (
            <div className="mb-4 mt-3">
                <h4> Summary </h4>
                <table className="apiLogDetailsTable table-sm">
                    <tbody>
                        <tr className="summaryRow">
                            <td className="summaryLabel"> ID </td>
                            <td> {apiRequestLogEntryDetails.id} </td>
                        </tr>
                        <tr className="summaryRow">
                            <td className="summaryLabel"> Time </td>
                            <td> {getFormattedDate(apiRequestLogEntryDetails.timestamp)} </td>
                        </tr>
                        <tr className="summaryRow">
                            <td className="summaryLabel"> Method </td>
                            <td> {requestSummaryObject.Method} </td>
                        </tr>
                        <tr className="summaryRow">
                            <td className="summaryLabel"> URL </td>
                            <td>{requestSummaryObject.RequestUri}</td>
                        </tr>
                        <tr className="summaryRow">
                            <td className="summaryLabel"> Status </td>
                            <td> {apiRequestLogEntryDetails.responseStatus} </td>
                        </tr>
                        <tr className="summaryRow">
                            <td className="summaryLabel"> IP address </td>
                            <td> 8.21.168.105</td>
                        </tr>
                        <tr className="summaryRow">
                            <td className="summaryLabel"> Version </td>
                            <td>{requestSummaryObject.Version}</td>
                        </tr>
                        <tr className="summaryRow">
                            <td className="summaryLabel"> Source </td>
                            <td>rdbranch/v1/0.0.3</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner loadingText="Loading..." />
            ) : (
                <div className="log-details">
                    <div className="back-to-logs-container" onClick={handleBackToLogList}>
                        <TextWithIcon
                            icon={<BackIcon />}
                            text="BACK TO API LOGS"
                            textClass="font-weight-bold"
                            iconClass="back-icon"
                        />
                    </div>
                    <div className="log-details__api-call">
                        <div className={getStatusDotClassName()}></div>
                        <div className="log-details__api-call__method-url">
                            {apiRequestLogEntryDetails.requestSummary}
                        </div>
                        <div className="log-details__api-call__time">
                            {getFormattedDate(apiRequestLogEntryDetails.timestamp)}
                        </div>
                    </div>
                    <hr />
                    {renderSummary(apiRequestLogEntryDetails)}
                    {renderRequest(apiRequestLogEntryDetails)}
                    {renderResponse(apiRequestLogEntryDetails)}
                    {/* https://github.com/ResDiary/ResDiary-DeveloperPortal/issues/234
          {renderErrors(
            errorCollapsed,
            setErrorCollapsed,
            apiRequestLogEntryDetails
          )} */}
                </div>
            )}
        </div>
    );
}

export default LogDetails;

// https://github.com/ResDiary/ResDiary-DeveloperPortal/issues/234
// const renderErrors = (
//   errorCollapsed,
//   setErrorCollapsed,
//   apiRequestLogEntryDetails
// ) => {
//   if (
//     !apiRequestLogEntryDetails.apiErrorLogEntries ||
//     !apiRequestLogEntryDetails.apiErrorLogEntries.length
//   ) {
//     return null;
//   }
//   return (
//     <>
//       <div className="log-details__request-title">Errors</div>
//       <div className="log-list__errors">
//         {apiRequestLogEntryDetails.apiErrorLogEntries.map((error) =>
//           renderError(errorCollapsed, setErrorCollapsed, error)
//         )}
//       </div>
//     </>
//   );
// };

// const renderError = (errorCollapsed, setErrorCollapsed, error) => {
//   const currentStatus = errorCollapsed[error.id];
//   const handleToggleErrorCollapsedClick = () => {
//     setErrorCollapsed({
//       ...errorCollapsed,
//       [error.id]: !currentStatus,
//     });
//   };

//   return (
//     <div className="error-log" key={error.id}>
//       <div className="row error-log__summary">
//         <div className="col-sm-2 error-log__title">
//           <div>Application:</div>
//           <div>Exception:</div>
//         </div>
//         <div className="col-sm-9 error-log__value">
//           <div>{error.application}</div>
//           <div>{error.message}</div>
//         </div>
//         <div className="col-sm-1">
//           <a
//             onClick={handleToggleErrorCollapsedClick}
//             className="float-right error-log__expand-details"
//           >
//             <img
//               src="/images/Chevron.svg"
//               className={currentStatus ? "rotate-180deg" : ""}
//             />
//           </a>
//         </div>
//       </div>
//       {!currentStatus ? (
//         <div className="row error-log__details">
//           <div className="col-sm-2 error-log__title">
//             <div>Occurred at:</div>
//             <div>File:</div>
//           </div>
//           <div className="col-sm-10 error-log__value">
//             <div>{error.method}</div>
//             <div>{error.fileName}</div>
//           </div>
//           <div className="col-sm-12">
//             {renderErrorStackTrace(error.stackTrace)}
//           </div>
//         </div>
//       ) : null}
//     </div>
//   );
// };

// const renderErrorStackTrace = (stackTrace) => {
//   return (
//     <div className="code error-log__stack-trace">
//       <div className="code__header">
//         <span className="code__header__title">Stack trace</span>
//       </div>
//       <pre>
//         <code>{stackTrace}</code>
//       </pre>
//     </div>
//   );
// };
