import * as React from 'react';
import { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';
import TextWithIcon from '../common/TextWithIcon';
import AppImageContainer from './AppImageContainer';
import CopyToClipboard from '../common/CopyToClipboard';
import App from '../../model/appManagement/App';
import AppService from '../../services/AppService';
import ErrorMessage from '../../model/services/ErrorMessage';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoadingSpinner from '../common/LoadingSpinner';
import { useAuthentication } from '../../contexts/AuthenticationContext';
import { ReactComponent as BackIcon } from '../../content/icons/Icon-Arrow-Left.svg';
import SideBarContentPage, { SideBarTabLabel } from '../common/SideBarContentPage';
import OAuthCredentialsPage from './appDetails/credentials/OAuthCredentialsPage';
import OAuthSettingsPage from './appDetails/settings/OAuthSettingsPage';
import TestDiariesPage from '../testDiaries/TestDiariesPage';
import AppAuthenticationSettings from '../../model/appManagement/AppAuthenticationSettings';
import { HubAlertProps } from '../common/hubAlert/HubAlert';
import HubAlertManager from '../common/hubAlert/HubAlertManager';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import AppWebhooksLogs from './AppWebhooksLogs';
import AppApiLogs from './AppApiLogs';
import AppWebhooksConfiguration from './AppWebhooksConfiguration';
import AppSubmissionStatusTag from './AppSubmissionStatusTag';
import AppApiTypeTag from './AppApiTypeTag';

const AppDetailsPage = () => {
    const [alerts, setAlerts] = useState<HubAlertProps[]>([]);
    const [application, setApplication] = useState<App>();
    const [authSettings, setAuthSettings] = useState<AppAuthenticationSettings>();
    const isTestEnvironment = true;
    const [isApplicationLoading, setIsApplicationLoading] = useState(true);
    const [isAuthSettingsLoading, setIsAuthSettingsLoading] = useState(true);
    const [currentTabKey, setCurrentTabKey] = useState(AppDetailsTabKeys.OAuthCredentials);
    const intl = useIntl();
    const { authInfo } = useAuthentication();
    const [secretKey, setSecretKey] = useState('');
    const navigate = useNavigate();
    const { appId } = useParams();
    const { state } = useLocation();
    const [isSecretKeyModalOpen, setIsSecretKeyModalOpen] = useState(false);

    useEffect(() => {
        if (state?.clientSecret) {
            setIsSecretKeyModalOpen(true);
            setSecretKey(state.clientSecret);
        }
    }, [state]);

    // Get application
    useEffect(() => {
        AppService.getApplicationById(appId!)
            .then((response) => {
                setApplication(response);
            })
            .catch((error: ErrorMessage) => {
                setAlerts((i) => [...i, { type: 'error', message: error.errorMessage }]);
            })
            .finally(() => {
                setIsApplicationLoading(false);
            });

        AppService.getApplicationAuthenticationSettings(appId!, isTestEnvironment)
            .then((response) => {
                setAuthSettings(response);
            })
            .catch((error: ErrorMessage) => {
                setAlerts((i) => [...i, { type: 'error', message: error.errorMessage }]);
            })
            .finally(() => {
                setIsAuthSettingsLoading(false);
            });
    }, [appId, isTestEnvironment]);

    const getAppId = () => {
        return (
            <div className="app-id-container">
                <Label>
                    <FormattedMessage id="AppManagement.AppNavigationBasePage.AppID" />
                    {` ${application!.appId}`}
                </Label>
                <CopyToClipboard isWhite={true} id="appId" getValue={() => application!.appId} />
            </div>
        );
    };

    const getTabLabels = (): SideBarTabLabel[] => {
        return [
            {
                key: AppDetailsTabKeys.OAuthCredentials,
                name: intl.formatMessage({ id: 'AppManagement.AppDetails.OAuth' }),
                isActive: currentTabKey === AppDetailsTabKeys.OAuthCredentials,
                subItems: [
                    {
                        key: AppDetailsTabKeys.OAuthCredentials,
                        name: intl.formatMessage({ id: 'AppManagement.AppDetails.Credentials' }),
                        isActive: currentTabKey === AppDetailsTabKeys.OAuthCredentials,
                    },
                    {
                        key: AppDetailsTabKeys.OAuthSettings,
                        name: intl.formatMessage({ id: 'AppManagement.AppDetails.Settings' }),
                        isActive: currentTabKey === AppDetailsTabKeys.OAuthSettings,
                    },
                ],
            },
            {
                key: AppDetailsTabKeys.TestDiaries,
                name: intl.formatMessage({ id: 'AppManagement.AppDetails.TestDiaries' }),
                isActive: currentTabKey === AppDetailsTabKeys.TestDiaries,
            },
            {
                key: AppDetailsTabKeys.ApiLogs,
                name: intl.formatMessage({ id: 'AppManagement.AppDetails.ApiLogs' }),
                isActive: currentTabKey === AppDetailsTabKeys.ApiLogs,
            },
            {
                key: AppDetailsTabKeys.WebhooksConfiguration,
                name: 'Webhooks',
                isActive: currentTabKey === AppDetailsTabKeys.WebhooksConfiguration,
                subItems: [
                    {
                        key: AppDetailsTabKeys.WebhooksConfiguration,
                        name: 'Webhooks Configuration',
                        isActive: currentTabKey === AppDetailsTabKeys.WebhooksConfiguration,
                    },
                    {
                        key: AppDetailsTabKeys.WebhooksLogs,
                        name: 'Webhook Logs',
                        isActive: currentTabKey === AppDetailsTabKeys.WebhooksLogs,
                    },
                ],
            },
        ];
    };

    const getTabs = () => {
        return [
            <OAuthCredentialsPage
                key={AppDetailsTabKeys.OAuthCredentials}
                appAuthSettings={authSettings!}
                isClientSecretModalOpen={isSecretKeyModalOpen}
                clientSecret={secretKey}
            />,
            <OAuthSettingsPage
                onSettingsUpdated={setAuthSettings}
                key={AppDetailsTabKeys.OAuthSettings}
                appAuthSettings={authSettings!}
            />,
            <AppApiLogs key={AppDetailsTabKeys.ApiLogs} application={application} isTestEnvironment={true} />,
            <AppWebhooksConfiguration
                key={AppDetailsTabKeys.WebhooksConfiguration}
                application={application}
                isTestEnvironment={true}
            />,
            <AppWebhooksLogs key={AppDetailsTabKeys.WebhooksLogs} app={application} />,
            <TestDiariesPage key={AppDetailsTabKeys.TestDiaries} app={application} />,
        ];
    };

    const onTabChanged = (tabKey: string) => {
        if (tabKey !== currentTabKey) {
            setCurrentTabKey(tabKey);
        }
    };

    if (isApplicationLoading || isAuthSettingsLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="app-navigation-page">
            <HubAlertManager alerts={alerts} />
            <div className="app-navigation-header">
                <div className="back-icon" onClick={() => navigate(!authInfo.isAdmin ? `/Apps` : '/Admin/Apps')}>
                    <TextWithIcon
                        icon={<BackIcon />}
                        className="text-uppercase"
                        text={intl.formatMessage({ id: 'AppManagement.AppDetails.BackToApps' })}
                        textClass="back-label"
                    />
                </div>

                <div className="app-info d-flex mt-2">
                    <div className="app-icon-container">
                        <AppImageContainer application={application!} imageUpdated={false} />
                    </div>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <h1>{application!.title}</h1>
                        </Grid>
                        <Grid spacing={1} xs={12} item container>
                            <Grid item>
                                <AppApiTypeTag appApiType={application!.apiType} />
                            </Grid>
                            <Grid item>
                                <AppSubmissionStatusTag submissionStatus={application!.submissionStatus} />
                            </Grid>
                            {!authSettings!.redirectUrl && (
                                <Grid item>
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: 'AppManagement.AppDetails.SettingsNotConfiguredMessage',
                                        })}
                                        arrow
                                        placement="top"
                                    >
                                        <span>
                                            <InfoIcon color="warning"></InfoIcon>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item>{getAppId()}</Grid>
                    </Grid>
                </div>
            </div>
            <SideBarContentPage
                tabLabels={getTabLabels()}
                currentTabKey={currentTabKey}
                tabs={getTabs()}
                onTabChanged={onTabChanged}
            />
        </div>
    );
};

export const AppDetailsTabKeys = {
    OAuthSettings: 'oauthsettings',
    OAuthCredentials: 'oauthcredentials',
    TestDiaries: 'testdiaries',
    ApiLogs: 'apilogs',
    WebhooksConfiguration: 'webhooksconfiguration',
    WebhooksLogs: 'webhookslogs',
};

export default AppDetailsPage;
