import * as React from 'react';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmationModal from '../common/ConfirmationModal';
import Stack from '@mui/material/Stack';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { useOrganisation } from '../../contexts/OrganisationContext';
import AppService from '../../services/AppService';
import appTypeEnum from '../../enums/appType';
import apiTypeEnum from '../../enums/apiType';
import { useNavigate } from 'react-router-dom';
import InfoBarManager from '../common/InfoBarManager';
import infoBarType from '../../enums/infoBarType';
import { InfoBarProps } from '../common/InfoBar';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorMessage from '../../model/services/ErrorMessage';

let _ID = 0;

interface CreateAppForm {
    appName: string;
    apiType: string;
}

interface CreateAppModalProps {
    isOpen: boolean;
    setIsOpen(isOpen: boolean): void;
}

const CreateAppModal = ({ isOpen, setIsOpen }: CreateAppModalProps) => {
    const [organisationId, setOrganisationId] = useState(0);
    const [appType, setAppType] = useState(appTypeEnum.private);
    const [apiType, setApiType] = useState<string>('');
    const [infoBars, setInfoBars] = useState<InfoBarProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { defaultOrganisation } = useOrganisation();
    const navigate = useNavigate();
    const titleCharacterLimit = 25;
    const intl = useIntl();

    const createAppSchema = Yup.object<CreateAppForm>().shape({
        appName: Yup.string()
            .transform((value) => (value === null || value === undefined ? undefined : value))
            .test(
                'len',
                intl.formatMessage(
                    { id: 'AppManagement.CreateAppModal.AppNameLength' },
                    { applength: titleCharacterLimit }
                ),
                (value) => typeof value === 'string' && value.length < titleCharacterLimit
            )
            .required(intl.formatMessage({ id: 'AppManagement.CreateAppModal.AppNameRequired' })),
        apiType: Yup.string()
            .transform((value) => (value === null || value === undefined ? undefined : value))
            .required(intl.formatMessage({ id: 'AppManagement.CreateAppModal.ApiTypeNotSelected' })),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        resetField,
    } = useForm<CreateAppForm>({ resolver: yupResolver(createAppSchema) });

    useEffect(() => {
        setOrganisationId(defaultOrganisation!.id);
        setAppType(appTypeEnum.public);
    }, [defaultOrganisation]);

    const verifyCreateAppParameters = () => {
        setIsLoading(true);

        handleSubmit((data: CreateAppForm) => {
            try {
                setIsLoading(true);
                AppService.createApplication(data.appName, organisationId, appType, data.apiType)
                    .then((response) => {
                        AppService.createOrUpdateAppStoreListingFromApp(response);
                        setIsOpen(false);
                        navigate(`/Apps/${response.appId}`, { state: { clientSecret: response.clientSecret } });
                    })
                    .catch((error: ErrorMessage) => {
                        addErrorBar(error.errorMessage);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } catch {
                setIsLoading(false);
            }
        })();
    };

    const addErrorBar = (message: string) => {
        setInfoBars((i) => [...i, { id: _ID++, type: infoBarType.error, message: message, expireIn: 0 }]);
    };

    const closeModal = () => {
        initModal();
        setIsOpen(!isOpen);
    };

    const initModal = () => {
        // App type is hard coded until the backend is refactored.
        setAppType(appTypeEnum.public);
        setApiType('');
        resetField('appName');
        setIsLoading(false);
    };

    const handleApiTypeChangeEvent = (e: any) => {
        if (e.target.value) {
            setApiType(e.target.value);
        }
    };
    return (
        <ConfirmationModal
            header={intl.formatMessage({ id: 'AppManagement.CreateAppModal.CreateApp' })}
            isOpen={isOpen}
            onClose={closeModal}
            onAccept={verifyCreateAppParameters}
            firstButtonText={intl.formatMessage({ id: 'AppManagement.CreateAppModal.CreateApp' })}
            isLoading={isLoading}
        >
            <div>
                <InfoBarManager infoBarArray={infoBars} />
                <Stack spacing={2}>
                    <FormGroup>
                        <InputLabel htmlFor="provider-id">
                            <FormattedMessage id="AppManagement.CreateAppModal.TitleHeader" />
                        </InputLabel>
                        <TextField
                            id="app-name"
                            type="text"
                            {...register('appName')}
                            variant="outlined"
                            placeholder={intl.formatMessage({ id: 'AppManagement.CreateAppModal.EnterAppName' })}
                            label={intl.formatMessage({ id: 'AppManagement.CreateAppModal.EnterAppName' })}
                            error={!!errors.appName || !!errors.root?.message}
                            helperText={errors.appName?.message}
                            data-testid="app-name"
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputLabel id="organisation-id-label">
                            <FormattedMessage id="AppManagement.CreateAppModal.Api" />
                        </InputLabel>
                        <FormControl error={errors.apiType !== undefined}>
                            <InputLabel id="api-type-label">
                                <FormattedMessage id="AppManagement.CreateAppModal.SelectApiType" />
                            </InputLabel>
                            <Select
                                labelId="api-type-label"
                                id="api-type"
                                value={apiType || ''}
                                label="select api"
                                {...register('apiType', {
                                    onChange: handleApiTypeChangeEvent,
                                })}
                            >
                                {Object.values(apiTypeEnum)?.map((a) => (
                                    <MenuItem key={a} value={a}>
                                        {a}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.apiType && <FormHelperText>{errors.apiType.message}</FormHelperText>}
                        </FormControl>
                    </FormGroup>
                </Stack>
            </div>
        </ConfirmationModal>
    );
};
export default CreateAppModal;
