import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import SelectList from '../../common/SelectList';
import SearchInput from '../../common/SearchInput';
import Paginator from '../../common/Paginator';
import textDisplay from '../../../helpers/TextDisplayHelper';
import { ReactComponent as AppsIcon } from '../../../content/icons/Icon-Apps.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import AppsTable from './AppsTable';
import { getPageSizes } from '../../../helpers/pagingHelper';
import AdminAppService from '../../../services/admin/AppService';
import LoadingSpinner from '../../common/LoadingSpinner';
import AppSubmissionStatuses from '../../../enums/appSubmissionStatus';

const AppListPage = () => {
    const [appStatuses, setAppStatuses] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pagedResult, setPagedResult] = useState<PagedResult>();
    const [searchFilter, setSearchFilter] = useState<string>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const intl = useIntl();

    useEffect(() => {
        AdminAppService.getAppsByPage({ pageNumber, pageSize, searchFilter })
            .then((response) => {
                if (!('errorMessage' in response)) {
                    let result = response;
                    setPagedResult({
                        data: result.data,
                        totalPages: result.totalPages,
                        pageNumber: result.pageNumber,
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [pageSize, searchFilter, pageNumber]);

    useEffect(() => {
        if (appStatuses.length === 0) {
            const statuses: { key: number; value: string }[] = [];
            Object.keys(AppSubmissionStatuses).forEach((key, index) => {
                statuses.push({
                    key: index,
                    value: textDisplay.formatPascalText(key.toUpperCase()),
                });
            });
            setAppStatuses(statuses);
        }
        setIsLoading(false);
    }, [appStatuses.length]);

    const handleAppStatusChange = (e: any) => {
        let copyAppStatuses = [...appStatuses];
        let selectedStatus = copyAppStatuses.filter((status: any) => {
            return status.key === Number(e.target.value);
        });
        setSearchFilter(selectedStatus[0].value);
    };

    const handlepageSizeChange = (e: any) => {
        setPageNumber(1);
        setPageSize(e.target.value);
    };

    const handleSearchFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilter(e.target.value);
    };

    const handlePageChange = (e: number) => {
        setPageNumber(e);
    };

    const emptyResult = () => {
        return (
            <div className="col-md-6 offset-md-3 pt-2 align-items-center text-center">
                <div className="text-center mt-5">
                    <AppsIcon className="svg-large" />
                </div>
                <div className="row m-3 p-2">
                    <p className="col-12">
                        <b>
                            <FormattedMessage id="Admin.AppList.NoApps" />
                        </b>
                    </p>
                    <p className="col-12 mb-0">
                        <FormattedMessage id="Common.CheckBackLater" />
                    </p>
                </div>
            </div>
        );
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Fragment>
            <div className="container-fluid content">
                <div className="row">
                    <div className="col-12">
                        <h2 className="mb-4">
                            <FormattedMessage id="Admin.AppList.Apps" />
                        </h2>
                        <p>
                            <FormattedMessage id="Admin.AppList.TitleDescription" />
                        </p>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <SearchInput
                                    placeholder={intl.formatMessage({ id: 'Admin.AppList.SearchPlaceholder' })}
                                    onChange={handleSearchFilterChange}
                                />
                            </div>
                            <div className="col-6 d-flex">
                                <div className="col-8 d-flex justify-content-end">
                                    <SelectList
                                        className=""
                                        label={intl.formatMessage({ id: 'Admin.AppList.FilterBy' })}
                                        options={appStatuses}
                                        onChange={handleAppStatusChange}
                                    />
                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    <SelectList
                                        label={intl.formatMessage({ id: 'Admin.AppList.PageSize' })}
                                        options={getPageSizes()}
                                        onChange={handlepageSizeChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="pt-3">
                                {!isLoading && pagedResult?.data.length === 0 ? (
                                    emptyResult()
                                ) : (
                                    <AppsTable apps={pagedResult?.data || []} isLoading={isLoading} />
                                )}
                            </div>
                            {(pagedResult?.totalPages ? pagedResult.totalPages : 0) > 1 && (
                                <Paginator
                                    pages={pagedResult?.totalPages ? pagedResult.totalPages : 0}
                                    handlePaginate={handlePageChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
interface PagedResult {
    data: any;
    totalPages: number;
    pageNumber: number;
}
export default AppListPage;
