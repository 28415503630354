import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Form, FormGroup, Label, Col, Button } from 'reactstrap';
import AppImageContainer from './../AppImageContainer';
import ValidationInput from './../../common/ValidationInput';
import useValidation from './../../../hooks/useValidation';
import RichTextEditor from '../../common/RichTextEditor';
import RadioButton from '../../common/RadioButton';
import { FormattedMessage, useIntl } from 'react-intl';
import appPricingEnum from '../../../enums/appPricing';
import appSubmissionStatus from '../../../enums/appSubmissionStatus';
import AppCategories from '../AppCategories';
import LoadingSpinner from '../../common/LoadingSpinner';
import AppService from '../../../services/AppService';
import AppStoreListing from '../../../domainObjects/AppStoreListing';
import appStoreListingCategoryEnum from '../../../enums/appStoreListingCategories';
import ConfirmationModal from '../../common/ConfirmationModal';
import { ReactComponent as ConfirmIcon } from '../../../content/icons/Icon-Confirmed.svg';
import App from '../../../model/appManagement/App';
import AppListingImagesContainer from '../AppListingImagesContainer';
import { ImageListType, ImageType } from 'react-images-uploading';
import AppStoreListingSubmissionLogsTable from '../admin/AppStoreListingSubmissionLogsTable';
import { useAuthentication } from '../../../contexts/AuthenticationContext';
import ErrorMessage from '../../../model/services/ErrorMessage';

function AppStoreListingSubmissionPage(props: AppStoreListingSubmissionPageProps): JSX.Element {
    const intl = useIntl();
    const { authInfo } = useAuthentication();
    const { errors, register, submit, submitDisabled, errorCount } = useValidation();
    const [appIconImage, setAppIconImage] = useState<ImageType>();
    const [categoryArray, setCategoryArray] = useState([]);
    const [appPricing, setAppPricing] = useState(appPricingEnum.Free);
    const [isLoading, setIsLoading] = useState(true);
    const [appStoreListing, setAppStoreListing] = useState<AppStoreListing>();
    const [showModal, setShowModal] = useState(false);
    const [disableForm, setDisabledForm] = useState(false);

    useEffect(() => {
        AppService.getAppStoreListing(props.application.id)
            .then((response) => {
                if (response === null || !('errorMessage' in response)) {
                    const appStoreListingObject = new AppStoreListing(response);
                    if (appStoreListingObject.id === 0) {
                        appStoreListingObject.name = props.application.title;
                        appStoreListingObject.shortDescription = props.application.description;
                        appStoreListingObject.builtBy = props.application.builtBy;
                        appStoreListingObject.categories = props.application.categories;
                        appStoreListingObject.termsOfServiceUrl = props.application.termsOfServiceUrl;
                        appStoreListingObject.privacyPolicyUrl = props.application.privacyPolicyUrl;
                        appStoreListingObject.supportEmail = props.application.supportEmail;
                    }
                    setAppStoreListing(appStoreListingObject);
                    setCategoryArray(appStoreListingObject.categories);
                    setAppPricing(appStoreListingObject.pricing);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
        console.log(authInfo);
    }, [props.application, authInfo]);

    const appIconImageOnChange = (imageList: ImageListType): void => {
        let imageToUpload = imageList[0];
        setAppIconImage(imageToUpload);
    };

    const withdrawApplistingSubmission = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        //go back to previous submission state which should be Approved for now when following the linear app progression
        e.preventDefault();
        props.setIsLoading(true);
        props.application.submissionStatus = appSubmissionStatus.approved;
        AppService.updateSubmissionStatus(props.application.id, appSubmissionStatus.approved)
            .then((response) => {
                props.application.submittedDate = response;
                props.onSuccess(
                    intl.formatMessage({
                        id: 'AppManagement.AppSubmissionStatus.WithdrawnSubmissionConfirmation',
                    })
                );
                setDisabledForm(false);
                setIsLoading(false);
            })
            .catch((error: ErrorMessage) => {
                // TODO: Handle error
                props.setIsLoading(false);
            });
    };

    function onValueChange(key: string, value: string): void {
        const newAppStoreListing: any = { ...appStoreListing };
        newAppStoreListing[key] = value;
        setAppStoreListing(newAppStoreListing);
    }

    function saveAppStoreListing(): void {
        let formData = configureFormData(appStoreListing!.submissionStatus);

        setIsLoading(true);
        AppService.createOrUpdateAppStoreListing(props.application.id, formData)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setAppStoreListing(new AppStoreListing(response));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function submitAppStoreListing(): void {
        props.setIsLoading(true);
        const currentSubmissionStatus = props.application.submissionStatus;
        props.application.submissionStatus = appSubmissionStatus.submitted;
        setDisabledForm(true);
        let data = new FormData();
        let formData = configureFormData(appSubmissionStatus.submitted);
        data.append('basicInformation', JSON.stringify(props.application));
        //only updating the AppStoreListing not the basic App Info.
        AppService.createOrUpdateAppStoreListing(props.application.id, formData)
            .then((updateResponse: any) => {
                if (updateResponse.ok) {
                    setAppStoreListing(new AppStoreListing(updateResponse.data));
                    //now update the submission status in the basic Apps info - this is due to be refactored to have submissionStatus in AppStoreListing
                    AppService.updateSubmissionStatus(props.application.id, appSubmissionStatus.submitted)
                        .then((response) => {
                            props.application.submittedDate = response;
                            props.onSuccess(
                                intl.formatMessage({
                                    id: 'AppManagement.AppStoreListingSubmissionPage.SubmitListingSuccessfulMessage',
                                })
                            );
                            setDisabledForm(false);
                        })
                        .catch((error: ErrorMessage) => {
                            //TODO: Handle error
                        });
                } else {
                    props.application.submissionStatus = currentSubmissionStatus;
                    props.onError(
                        intl.formatMessage({
                            id: 'AppManagement.AppStoreListingSubmissionPage.SubmitListingErrorMessage',
                        })
                    );
                }
            })
            .finally(() => {
                props.setIsLoading(false);
            });
    }

    function configureFormData(submissionStatus: string): FormData {
        let data = new FormData();
        appStoreListing!.categories = categoryArray;
        appStoreListing!.pricing = appPricing;
        appStoreListing!.submissionStatus = submissionStatus;
        let appStoreListingData = new AppStoreListing(appStoreListing);
        data.append('appStoreListing', JSON.stringify(appStoreListingData.toJson()));
        return data;
    }

    function setEditorState(value: string): void {
        const appStoreListingCopy: any = { ...appStoreListing };
        appStoreListingCopy.longDescription = value;
        setAppStoreListing(appStoreListingCopy);
    }

    function renderSubmissionMessage(): JSX.Element {
        switch (props.application.submissionStatus) {
            case appSubmissionStatus.submitted:
                return <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.PageDescription" />;
            case appSubmissionStatus.live:
                return (
                    <div className="row pl-2 pt-0">
                        <div>
                            <ConfirmIcon className="p-1" />
                        </div>
                        <div className="listing-live pt-1">
                            <h6>
                                <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.ListingLiveMessage" />
                            </h6>
                        </div>
                    </div>
                );
            case appSubmissionStatus.approved:
                return (
                    <div className="row pl-2 pt-0">
                        <div>
                            <ConfirmIcon className="p-1" />
                        </div>
                        <div className="listing-live pt-1">
                            <h6>
                                <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.ListingLiveMessage" />
                            </h6>
                        </div>
                    </div>
                );
            default:
                return <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.PageDescription" />;
        }
    }

    function renderSubmissionButtonGroup(): JSX.Element {
        switch (props.application?.submissionStatus) {
            case appSubmissionStatus.inDevelopment:
            default:
                return (
                    <Fragment>
                        <Button
                            type="submit"
                            color="primary"
                            className="mr-2 btn btn-outline-primary"
                            disabled={disableForm}
                        >
                            <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.Save" />
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            className="mr-2"
                            disabled={submitDisabled || errorCount > 0 || disableForm}
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.SubmitListing" />
                        </Button>
                    </Fragment>
                );
            case appSubmissionStatus.submitted:
                return (
                    <Button
                        type="submit"
                        color="btn btn-outline-primary"
                        className="mr-2"
                        onClick={withdrawApplistingSubmission}
                    >
                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.WithdrawSubmission" />
                    </Button>
                );
        }
    }

    const renderAppListingImagesContainer = () => {
        return <AppListingImagesContainer application={props.application} isDisabled={disableForm} />;
    };

    function renderModal(): JSX.Element {
        return (
            <ConfirmationModal
                header={intl.formatMessage({
                    id: 'AppManagement.AppStoreListingSubmissionPage.ConfirmSubmitForm',
                })}
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onAccept={(e: any): void => {
                    e.preventDefault();
                    submit(submitAppStoreListing);
                    setShowModal(false);
                }}
            />
        );
    }

    if (isLoading) {
        return <LoadingSpinner />;
    } else {
        return (
            <Fragment>
                <div className="submission-page">
                    <h2 className="mb-4">
                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.PageTitle" />
                    </h2>
                    <div className="mb-4 submission-page-info-section">{renderSubmissionMessage()}</div>
                    <hr />
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (!showModal) {
                                submit(saveAppStoreListing);
                            }
                        }}
                    >
                        <fieldset>
                            {authInfo.isAdmin && (
                                <FormGroup row>
                                    <AppStoreListingSubmissionLogsTable application={props.application} />
                                </FormGroup>
                            )}
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.AppIcon" />
                                    </h6>
                                </Label>
                                <Label sm={4} className="pt-0 pb-0">
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.DimensionText" />
                                    </span>
                                    <br />
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.FileSizeText" />
                                    </span>
                                    <br />
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.FileFormatText" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <Fragment>
                                        <AppImageContainer
                                            appImage={appIconImage}
                                            requireUpload
                                            application={props.application}
                                            onChange={appIconImageOnChange}
                                            isDisabled={disableForm}
                                        />
                                    </Fragment>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.AppName" />
                                    </h6>
                                </Label>
                                <Label sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.AppNameDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <ValidationInput
                                        disabled={disableForm}
                                        type="text"
                                        name="title"
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.AppNamePlaceholder',
                                        })}
                                        value={appStoreListing!.name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onValueChange('name', e.currentTarget.value);
                                        }}
                                        maxLength={25}
                                        displayCharacterCount={true}
                                        errors={errors}
                                        innerRef={register({
                                            required: intl.formatMessage({
                                                id: 'AppManagement.AppStoreListingSubmissionPage.AppNameInnerRef',
                                            }),
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.Description" />
                                    </h6>
                                </Label>
                                <Label for="appDescription" sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.DescriptionText" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <ValidationInput
                                        disabled={disableForm}
                                        type="textarea"
                                        name="description"
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.DescriptionPlaceholder',
                                        })}
                                        value={appStoreListing!.shortDescription}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onValueChange('shortDescription', e.currentTarget.value);
                                        }}
                                        maxLength={70}
                                        errors={errors}
                                        displayCharacterCount={true}
                                        innerRef={register({
                                            required: intl.formatMessage({
                                                id: 'AppManagement.AppStoreListingSubmissionPage.DescriptionInnerRef',
                                            }),
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.LongDescription" />
                                    </h6>
                                </Label>
                                <Label for="appLongDescription" sm={4}>
                                    <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.LongDescriptionText" />
                                </Label>
                                <Col sm={6} className={disableForm ? 'disable-editor' : ''}>
                                    <RichTextEditor
                                        content={appStoreListing!.longDescription}
                                        setEditorState={setEditorState}
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.LongDescriptionPlaceholder',
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.BuiltBy" />
                                    </h6>
                                </Label>
                                <Label for="companyName" sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.BuiltByDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <ValidationInput
                                        disabled={disableForm}
                                        type="text"
                                        name="builtBy"
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.BuiltByPlaceholder',
                                        })}
                                        value={appStoreListing!.builtBy}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onValueChange('builtBy', e.currentTarget.value);
                                        }}
                                        maxLength={25}
                                        displayCharacterCount={true}
                                        errors={errors}
                                        innerRef={register({
                                            required: intl.formatMessage({
                                                id: 'AppManagement.AppStoreListingSubmissionPage.BuiltByInnerRef',
                                            }),
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.Categories" />
                                    </h6>
                                </Label>
                                <Label for="appCategories" sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.CategoriesDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <AppCategories
                                        categoryArray={categoryArray}
                                        setCategoryArray={setCategoryArray}
                                        appCategoryEnum={appStoreListingCategoryEnum}
                                        isFormDisabled={disableForm}
                                    />
                                </Col>
                            </FormGroup>
                            <hr />
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.AppImages" />
                                    </h6>
                                </Label>
                                <Label sm={4} className="pt-0 pb-0">
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.AppImageDescription" />
                                    </span>
                                    <br />
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.AppImageFileFormat" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <Fragment>{renderAppListingImagesContainer()}</Fragment>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.VideoLink" />
                                    </h6>
                                </Label>
                                <Label for="videoLink" sm={4} className="pt-0 pb-0">
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.VideoDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <ValidationInput
                                        disabled={disableForm}
                                        type="url"
                                        name="appVideoUrl"
                                        value={appStoreListing!.videoLink}
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.VideoLinkPlaceholder',
                                        })}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onValueChange('videoLink', e.currentTarget.value);
                                        }}
                                        errors={errors}
                                        innerRef={register({
                                            pattern: {
                                                value: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi, // eslint-disable-line no-control-regex
                                                message: intl.formatMessage({
                                                    id: 'AppManagement.AppStoreListingSubmissionPage.VideoLinkInnerRef',
                                                }),
                                            },
                                        })}
                                    />
                                </Col>
                            </FormGroup>
                            <hr />
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.Pricing" />
                                    </h6>
                                </Label>
                                <Label for="appPricing" sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.PricingDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <RadioButton
                                        disabled={disableForm}
                                        id={`app-pricing-${appPricingEnum.Free}`}
                                        label="Free"
                                        description={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.PricingDescriptionFree',
                                        })}
                                        className="pt-2 pb-2"
                                        name="pricingInfo-Free"
                                        value={appPricingEnum.Free}
                                        onChange={(value: string): void => {
                                            setAppPricing(value);
                                        }}
                                        checked={appPricing === appPricingEnum.Free}
                                    />
                                    <RadioButton
                                        disabled={disableForm}
                                        id={`app-pricing-${appPricingEnum.Paid}`}
                                        label="Paid"
                                        description={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.PricingDescriptionPaid',
                                        })}
                                        className="pt-2 pb-2"
                                        name="pricingInfo-Paid"
                                        value={appPricingEnum.Paid}
                                        onChange={(value: string): void => {
                                            setAppPricing(value);
                                        }}
                                        checked={appPricing === appPricingEnum.Paid}
                                    />
                                </Col>
                            </FormGroup>
                            <hr />
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.WebsiteUrl" />
                                    </h6>
                                </Label>
                                <Label for="appWesiteUrl" sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.WebsiteUrlDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <ValidationInput
                                        disabled={disableForm}
                                        type="url"
                                        name="appWebsiteUrl"
                                        value={appStoreListing!.websiteUrl}
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.WebsiteUrlPlaceholder',
                                        })}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onValueChange('websiteUrl', e.currentTarget.value);
                                        }}
                                        innerRef={register({
                                            required: 'Website URL is required',
                                            pattern: {
                                                value: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi, // eslint-disable-line no-control-regex
                                                message: intl.formatMessage({
                                                    id: 'AppManagement.AppStoreListingSubmissionPage.WebsiteUrlInnerRef',
                                                }),
                                            },
                                        })}
                                        errors={errors}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.TermsOfServiceUrl" />
                                    </h6>
                                </Label>
                                <Label for="appTermsOfService" sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.TermsOfServiceUrlDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <ValidationInput
                                        disabled={disableForm}
                                        type="url"
                                        name="termsOfServiceUrl"
                                        value={appStoreListing!.termsOfServiceUrl}
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.TermsOfServicePlaceholder',
                                        })}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onValueChange('termsOfServiceUrl', e.currentTarget.value);
                                        }}
                                        innerRef={register({
                                            required: 'Terms of service URL is required',
                                            pattern: {
                                                value: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi, // eslint-disable-line no-control-regex
                                                message: intl.formatMessage({
                                                    id: 'AppManagement.AppStoreListingSubmissionPage.TermsOfServiceInnerRef',
                                                }),
                                            },
                                        })}
                                        errors={errors}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.PrivacyNoticeUrl" />
                                    </h6>
                                </Label>
                                <Label for="appProvacyPolicyURL" sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.PrivacyNoticeUrlDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <ValidationInput
                                        disabled={disableForm}
                                        type="url"
                                        name="privacyPolicyUrl"
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.PrivacyPolicyPlaceholder',
                                        })}
                                        value={appStoreListing!.privacyPolicyUrl}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onValueChange('privacyPolicyUrl', e.currentTarget.value);
                                        }}
                                        innerRef={register({
                                            required: 'Privacy Notice URL is required',
                                            pattern: {
                                                value: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi, // eslint-disable-line no-control-regex
                                                message: intl.formatMessage({
                                                    id: 'AppManagement.AppStoreListingSubmissionPage.PrivacyPolicyInnerRef',
                                                }),
                                            },
                                        })}
                                        errors={errors}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={12} className="pt-0 pb-0">
                                    <h6>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.SupportEmail" />
                                    </h6>
                                </Label>
                                <Label for="appSupportEmail" sm={4}>
                                    <span>
                                        <FormattedMessage id="AppManagement.AppStoreListingSubmissionPage.SupportEmailDescription" />
                                    </span>
                                </Label>
                                <Col sm={6}>
                                    <ValidationInput
                                        disabled={disableForm}
                                        type="email"
                                        placeholder={intl.formatMessage({
                                            id: 'AppManagement.AppStoreListingSubmissionPage.SupportEmailPlaceholder',
                                        })}
                                        name="supportEmail"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            onValueChange('supportEmail', e.currentTarget.value);
                                        }}
                                        value={appStoreListing!.supportEmail}
                                        innerRef={register({
                                            required: 'Support email address is required',
                                            pattern: {
                                                value: /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi, // eslint-disable-line no-control-regex
                                                message: intl.formatMessage({
                                                    id: 'AppManagement.AppStoreListingSubmissionPage.SupportEmailInnerRef',
                                                }),
                                            },
                                        })}
                                        errors={errors}
                                    />
                                </Col>
                            </FormGroup>
                        </fieldset>
                        <hr />
                        <FormGroup row>{renderSubmissionButtonGroup()}</FormGroup>
                    </Form>
                    {renderModal()}
                </div>
            </Fragment>
        );
    }
}

interface AppStoreListingSubmissionPageProps {
    application: App;
    onSuccess(message: string): void;
    onError(message: string): void;
    setIsLoading(isLoading: boolean): void;
}

export default AppStoreListingSubmissionPage;
