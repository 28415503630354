import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import LogTable from '../common/LogTable';
import WebhooksService from '../../services/WebhooksService';
import environment from '../../enums/environment.js';
import { FormattedMessage, useIntl } from 'react-intl';
import App from '../../model/appManagement/App';
import GetLogsRequest from '../../model/webhooks/GetLogsRequest';

interface AppWebhooksLogsProps {
    app?: App;
}

const AppWebhooksLogs = ({ app }: AppWebhooksLogsProps) => {
    const intl = useIntl();
    const [logData, setLogData] = useState<any>([]);
    const [expandedRow, setExpandedRow] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [nextPageUrl, setNextPageUrl] = useState<any>();

    const [filter, setFilter] = useState({
        responseStatus: null,
        environment: environment.test, // Set test environment as default for now.
    });

    const columns = [
        {
            name: intl.formatMessage({ id: 'AppManagement.WebhookLogs.Time' }),
            selector: (x: any) => x.timestamp.replace('T', ' ').replace(/\.\d+/, ''),
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.WebhookLogs.Url' }),
            selector: (x: any) => x.url,
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.WebhookLogs.Status' }),
            selector: (x: any) => (x.statusCode > 0 ? x.statusCode : ''),
        },
        {
            name: '',
            displayFormat: (x: any) => {
                const statusDotClassName = `status-dot ${x.success ? '' : 'status-dot--fail'}`;
                return (
                    <Fragment>
                        <div className={statusDotClassName}></div>
                        {x.responseMessage}
                    </Fragment>
                );
            },
        },
        {
            name:
                intl.formatMessage({ id: 'AppManagement.WebhookLogs.Time' }) +
                ' ' +
                intl.formatMessage({ id: 'AppManagement.WebhookLogs.MS' }),
            selector: (x: any) => x.elapsedMilliseconds,
        },
        {
            name: intl.formatMessage({ id: 'AppManagement.WebhookLogs.Rqst' }),
            displayFormat: (x: any, index: any) => (
                <button
                    className="log-table__goto-detail-page float-right button-link"
                    onClick={() => {
                        setExpandedRow(expandedRow === index ? null : index);
                    }}
                >
                    <img
                        src="/images/Chevron.svg"
                        className={`${expandedRow === index ? '' : 'rotate-180deg'}`}
                        alt=""
                    />
                </button>
            ),
        },
    ];

    useEffect(() => {
        setIsLoading(true);
        let params: GetLogsRequest = {
            responseStatus: undefined,
            isTestEnvironment: filter.environment === environment.test,
            appId: app?.id,
        };
        WebhooksService.getLogs(params)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setLogData(response.data);
                    setNextPageUrl(response.nextPageUrl);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [app?.id, filter]);

    const handleLoadMoreData = async () => {
        setIsLoading(true);
        WebhooksService.getNextLogs(nextPageUrl)
            .then((response) => {
                if (!('errorMessage' in response)) {
                    setLogData([...logData, ...response.data]);
                    setNextPageUrl(response.nextPageUrl);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const expandRow = {
        renderer: (row: any) => {
            return (
                <Fragment>
                    <div className="code">
                        <div className="code__header">
                            <span className="code__header__title">
                                <FormattedMessage id="AppManagement.WebhookLogs.RqstDetails" />
                            </span>
                        </div>
                        {row.logRequest ? (
                            <>
                                <div className="code-separator"></div>
                                <pre>
                                    <code>{JSON.stringify(JSON.parse(row.logRequest), null, 4)}</code>
                                </pre>
                            </>
                        ) : null}
                    </div>
                </Fragment>
            );
        },
        expanded: expandedRow,
    };

    return (
        <div className="container-fluid">
            <div className="row flex-xl-nowrap">
                <div className="logs-page col-12">
                    <h2 className="my-4">
                        <FormattedMessage id="AppManagement.WebhookLogs.PageHeader" />
                    </h2>
                    <LogTable
                        tableName={intl.formatMessage({ id: 'AppManagement.WebhookLogs.PageHeader' })}
                        columns={columns}
                        data={logData}
                        isLoading={isLoading}
                        nextPageUrl={nextPageUrl}
                        handleLoadMoreClick={handleLoadMoreData}
                        filter={filter}
                        setFilter={setFilter}
                        expandRow={expandRow}
                    />
                </div>
            </div>
        </div>
    );
};

export default AppWebhooksLogs;
