import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TextFieldWIthCopyToClipboard from '../settings/TextFieldWIthCopyToClipboard';

const ClientIdCard = (props: ClientIdCardProps) => {
    return (
        <div className="mb-4 oauth-card">
            <h5 className="mb-3">
                <FormattedMessage id="AppManagement.AppDetails.ClientId" />
            </h5>
            <TextFieldWIthCopyToClipboard
                id="clientId"
                value={props.clientId}
                readOnly={true}
                getValue={() => props.clientId}
            />
        </div>
    );
};

export interface ClientIdCardProps {
    clientId: string;
}

export default ClientIdCard;
